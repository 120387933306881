$(document).ready(function () {

	customSelect();
	sliderCounter();
	initSlider();
	sliderButtonsPosition();
	subnav();
	grid();
	objectFitImg();
	autocomplete();
	stickyHeader();
	galleryGrid();
});


var resizeId;
$(window).resize(function () {
	clearTimeout(resizeId);
	resizeId = setTimeout(doneResizing, 100);
});

$(document).on('scroll', function () {
	stickyHeader();
});
var respNavs = {}
$('.js-header-nav-link').on('mouseenter', function () {
	var $this = $(this);
	var $linkData = $this.data('link');
	$('.js-header-respnav').each(function () {
		var $subMenuData = $(this).data('link');
		if ($subMenuData === $linkData) {
			if(!$(this).hasClass('is-opened')){
				$(this).addClass('is-opened');
				respNavs[$linkData] = new ResponsiveNavigation($(this)[0]);
			}
		}
	});
});
// $('.js-header-nav-link').on('mouseleave', function () {
// 	var $this = $(this);
// 	var $linkData = $this.data('link');
// 	$('.js-header-respnav').each(function () {
// 		var $subMenuData = $(this).data('link');
// 		if ($subMenuData === $linkData) {
// 			respNavs[$linkData].destroy();
// 			$(this).removeClass('is-opened');
// 			console.log($(this));
// 		}
// 	});
// });
// tabs
$('.js-tabs-nav-button').on('click', function (e) {
	e.preventDefault();
	var $this = $(this),
		tabs = $this.parents('.js-tabs'),
		tabsContentItem = tabs.find('.js-tabs-content-item'),
		tabIndex = $this.index();
	if (!$this.hasClass('active')) {
		tabs.find('.js-tabs-nav-button.active').removeClass('active');
		$this.addClass('active');
		tabsContentItem.slideUp('fast');
		tabsContentItem.eq(tabIndex).slideDown('fast');
	}
});

// open/close menu
$(document).on('click', '.js-menu-toggle', function () {
	var buttonType = $(this).data('menu-item');

	if (buttonType) {
		$('.js-menu-item.active').removeClass('active');
		$('.js-menu-item[data-menu-item="' + buttonType + '"]').addClass('active');

		if (buttonType == 'search') {
			$('#menu-search').focus();
		}
	}

	$('body').toggleClass('menu-opened');
});

$(document).on('click', '.js-popup-toggle', function () {
	var $this = $(this);
	var popupType = $(this).data('popup');
	var popup;
	$('.js-popup.active').removeClass('active');

	if (popupType) {
		popup = $('.js-popup[data-popup="' + popupType + '"]');
		popup.addClass('active');
		if (popupType === 'horoscope') {
			horoscopePopup($this, popup);
		}
	}

	$('body').toggleClass('popup-opened');
});
function horoscopePopup(el, popup) {
	var horoscopeTitle = el.data('title');
	var horoscopeIcon = el.data('url');
	var horoscopeText = el.data('text');
	var popupTitle = popup.find('.js-popup-title');
	var popupIcon = popup.find('.js-popup-icon');
	var popupText = popup.find('.js-popup-text');

	popupTitle.text(horoscopeTitle);
	popupIcon.find('use')[0].setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', horoscopeIcon);
	popupText.text(horoscopeText);

}
// menu nav button
$(document).on('click', '.js-menu-nav-toggle', function () {
	var $this = $(this);

	$this.parents('.js-menu-nav').toggleClass('active');
	$this.parents('.js-menu-nav-item').toggleClass('active');
});

// map region
$(document).on('click', '.js-map-region-item', function () {
	window.location.replace($(this).data('htooltip'));
});

// close popups on popup-overlay clicked
$(document).on('click', '.js-popup-overlay', function () {
	closePopups();
});

// show and scroll
$(document).on('click', '.js-show-scroll-button', function (e) {
	e.preventDefault();
	var $this = $(this),
		sectionToShow = $('*[data-section="' + $this.data('show') + '"]'),
		sectionToScroll = $('*[data-section="' + $this.data('scroll') + '"]');

	if (!sectionToShow.hasClass('active')) {
		sectionToShow.addClass('active');
		sectionToShow.stop(true, true).slideDown();
	}
	scrollToElement(sectionToScroll);

})

$(document).keyup(function (e) {
	if (e.keyCode == 27) {
		closePopups();
	}
});

// tooltips
if ($('html').hasClass('no-touch')) {
	$(document).on('mouseenter', '.js-tooltip', function () {
		var tooltip = $(this),
			tooltipContainer = $('.js-tooltip-container', tooltip);
		if (tooltipContainer.is(':visible')) {
			tooltipContainer.hide();
		}
		else {
			$('.js-tooltip-container').hide();
			tooltipContainer.show();
		}
		var popper = new Popper(tooltip, tooltipContainer, {
			placement: 'bottom',
			modifiers: {
				preventOverflow: {
					boundariesElement: 'viewport'
				}
			}
		});
	}).on('mouseleave', '.js-tooltip', function () {
		$('.js-tooltip-container').hide();
	});
}

function doneResizing() {
	sliderButtonsPosition();
	grid();
	stickyHeader();
	initSlider();
}

function initSlider() {
	if ($('.js-slider-holder').length) {
		$('.js-slider-holder').each(function () {
			if (!$(this).find('.slick-initialized').length) {
				var sliderHolder = $(this),
					slider = sliderHolder.find('.js-slider'),
					sliderFor = sliderHolder.find('.js-slider-for'),
					sliderNav = sliderHolder.find('.js-slider-nav'),
					sliderPrevButton = sliderHolder.find('.js-slider-prev-button'),
					sliderNextButton = sliderHolder.find('.js-slider-next-button'),
					sliderDots = sliderHolder.find('.js-slider-dots'),
					sliderSettings = {},
					syncSliderSettingFor = {},
					syncSliderSettingNav = {};

				if ($('.js-slider-settings', sliderHolder).length) {
					sliderSettings = JSON.parse(sliderHolder.find('.js-slider-settings').html());

					if (sliderPrevButton.length) {
						sliderSettings.prevArrow = sliderPrevButton;
					}
					else {
						sliderSettings.prevArrow = false;
					}
					if (sliderNextButton.length) {
						sliderSettings.nextArrow = sliderNextButton;
					}
					else {
						sliderSettings.nextArrow = false;
					}
					if (sliderDots.length) {
						sliderSettings.appendDots = sliderDots;
					}

					sliderSettings.adaptiveHeight = true;

					slider.slick(sliderSettings);
				}

				if ($('.js-sync-slider-settings-for', sliderHolder).length && $('.js-sync-slider-settings-nav', sliderHolder).length) {
					syncSliderSettingFor = JSON.parse(sliderHolder.find('.js-sync-slider-settings-for').html());
					syncSliderSettingFor.asNavFor = sliderNav;
					syncSliderSettingFor.adaptiveHeight = true;
					sliderFor.slick(syncSliderSettingFor);

					syncSliderSettingNav = JSON.parse(sliderHolder.find('.js-sync-slider-settings-nav').html());
					if (sliderPrevButton.length) {
						syncSliderSettingNav.prevArrow = sliderPrevButton;
					}
					else {
						syncSliderSettingNav.prevArrow = false;
					}
					if (sliderNextButton.length) {
						syncSliderSettingNav.nextArrow = sliderNextButton;
					}
					else {
						syncSliderSettingNav.nextArrow = false;
					}
					syncSliderSettingNav.asNavFor = sliderFor;
					sliderNav.slick(syncSliderSettingNav);
				}
			}
		});
	}
}

function sliderButtonsPosition() {
	var highestSliderItem = 0;

	if ($('.js-slider-holder').length) {
		$('.js-slider-holder').each(function () {
			var sliderHolder = $(this),
				sliderHeightMeasure = sliderHolder.find('.js-slider-height-measure');
			if (sliderHeightMeasure.length) {
				sliderHeightMeasure.each(function () {
					var sliderHeightMeasureItem = $(this).outerHeight();

					if (sliderHeightMeasureItem > highestSliderItem) {
						highestSliderItem = sliderHeightMeasureItem;
					}
				});

				var sliderButtons = sliderHolder.find('.js-slider-buttons button');
				if (!sliderButtons.hasClass('positioned')) {
					sliderButtons.addClass('positioned');
				}
				sliderButtons.css('top', highestSliderItem / 2 + 'px');
			}
		});
	}
}

function customSelect() {
	if ($('.js-select').length && $('html').hasClass('no-touch')) {
		$('.js-select').each(function () {
			$(this).select2({
				dropdownParent: $(this).parent()
			});
		});
	}
}

function objectFitImg() {
	if ($('.js-object-fit-img').length) {
		var objectFitImg = $('.js-object-fit-img');
		objectFitImages(objectFitImg);
	}
}

function grid() {
	var laptop = 1024,
		tablet = 768,
		phablet = 640,
		phone = 480;

	if ($('.js-grid').length) {
		$('.js-grid').each(function () {
			var grid = $(this),
				gridItem = grid.find('.js-grid-item'),
				gridItemBig = grid.find('.js-grid-item--big'),
				gridCol = grid.find('.js-grid-col'),
				gridMainCol = grid.find('.js-grid-main-col'),
				gridMainGroup = gridMainCol.find('.js-grid-main-group'),
				gridItemsLength = gridItem.length,
				windowWidth = $(window).innerWidth(),
				breakpoint = 'desktop',
				col = 0;

			if (windowWidth > laptop) {
				breakpoint = 'desktop';
			}
			else if (windowWidth <= laptop && windowWidth > tablet) {
				breakpoint = 'laptop';
			}
			else if (windowWidth <= tablet && windowWidth > phablet) {
				breakpoint = 'tablet';
			}
			else if (windowWidth <= phablet && windowWidth > phone) {
				breakpoint = 'phablet';
			}
			else if (windowWidth <= phone) {
				breakpoint = 'phone';
			}

			var cols = parseInt(grid.data('cols-' + breakpoint + ''));

			if (gridItem.length < cols) {
				cols = gridItem.length;
			}

			if (!grid.hasClass('ready')) {
				gridItem.each(function () {
					if (gridItemBig.length) {
						$(this).attr('data-index', $(this).index());
					}
					else {
						$(this).attr('data-index', $(this).index() + 1);
					}
				});
				grid.addClass('ready');
			}

			if (gridCol.length) {
				if (cols != gridCol.length) {
					gridItem.unwrap();
					grid.removeClass('equal');
					for (var i = 1; i <= cols; i++) {
						grid.append('<div class="grid__col js-grid-col" data-col="' + i + '"></div>');
					}
				}
			}
			else {
				for (var i = 1; i <= cols; i++) {
					grid.append('<div class="grid__col js-grid-col" data-col="' + i + '"></div>');
				}
			}

			if (!grid.hasClass('equal')) {
				while (col < cols) {
					for (var i = 1; i <= gridItemsLength; i += cols) {
						grid.find('.js-grid-col[data-col="' + (col + 1) + '"]').append($('.js-grid-item[data-index="' + (i + col) + '"]', grid));
					}

					col++;
				}
				if (!gridItemBig.length) {
					grid.addClass('equal');
				}
				else {
					var mainCols = cols;

					if (gridMainCol.length) {
						gridMainGroup.unwrap().remove();
					}
					if (cols == 1) {
						mainCols = 2;
					}
					for (var i = 1; i <= mainCols - 1; i++) {
						grid.append('<div class="grid__main-col js-grid-main-col" data-main-col="' + i + '"><div class="grid__main-group js-grid-main-group"></div>');
					}

					gridItemBig.appendTo($('.js-grid-main-col[data-main-col="1"] .js-grid-main-group'));

					for (var j = 1; j <= cols; j++) {
						var gridMainColsLength = grid.find('.js-grid-main-col').length;
						if (j + 1 > gridMainColsLength) {
							$('.js-grid-main-col[data-main-col="1"] .js-grid-main-group', grid).append($('.js-grid-col[data-col="' + j + '"]', grid));
						}
						else {
							$('.js-grid-main-col[data-main-col="' + (j + 1) + '"] .js-grid-main-group', grid).append($('.js-grid-col[data-col="' + j + '"]', grid));
						}
					}

					grid.addClass('equal');
				}
			}
		});
	}
}

function subnav() {
	if ($('.js-header-subnav').length) {
		var nav = priorityNav.init({
			mainNavWrapper: '.js-header-subnav', // mainnav wrapper selector (must be direct parent from mainNav)
			mainNav: '.js-header-subnav-list', // mainnav selector. (must be inline-block)
			navDropdownClassName: 'main-header__subnav-dropdown', // class used for the dropdown.
			navDropdownToggleClassName: 'nav__dropdown-toggle', // class used for the dropdown toggle.
			navDropdownToggleClassName: 'main-header__subnav-button',
			// navDropdownLabel: '<span class="three-dots"></span>',
			navDropdownBreakpointLabel: '<span class="three-stripes"></span>'
		});
		//priority nav on hover
		if ($('html').hasClass('no-touch')) {
			$(document).on('mouseenter', '.main-nav__dropdown-wrapper', function () {
				$(this).find('.main-header__subnav-button').trigger('click');
			});
			$('.main-nav__dropdown-wrapper').on('mouseleave', function () {
				if ($('.js-header-subnav').hasClass('is-open')) {
					$(this).find('.main-header__subnav-button').trigger('click');
				}
			});
		}
	}
}

function autocomplete() {
	if ($('.js-autocomplete').length) {
		$('.js-autocomplete').each(function () {
			var $this = $(this);

			if ($('.js-autocomplete-options').length) {
				var autocompleteOptions = JSON.parse($('.js-autocomplete-options').html());
			}

			$this.easyAutocomplete(autocompleteOptions);
		});
	}
}


function stickyHeader() {
	if ($('.js-sticky-header').length) {
		var stickyHeader = $('.js-sticky-header'),
			windowHeight = $(window).height(),
			scroll = $(window).scrollTop(),
			scrollBottom = scroll + windowHeight,
			headerHeight = $('.main-header').height(),
			sectionProgress = $('.js-progress');
		if (sectionProgress.length) {
			var sectionProgressHeight = sectionProgress.height(),
				sectionProgressTop = sectionProgress.position().top,
				sectionProgressBottom = sectionProgressTop + sectionProgressHeight,
				percent = 0;
		}

		if (scroll >= headerHeight) {
			if (!stickyHeader.hasClass('active')) {
				stickyHeader.addClass('active');
			}

			if (scroll >= sectionProgressTop && scrollBottom <= sectionProgressBottom || scroll < sectionProgressTop && scrollBottom <= sectionProgressBottom) {
				percent = (((scrollBottom - sectionProgressTop) / sectionProgressHeight) * 100);
			}
			else if (scrollBottom > sectionProgressBottom) {
				percent = 100;
			}
			else if (scrollBottom < sectionProgressTop) {
				percent = 0;
			}

			$('.js-sticky-header-progress').css('width', percent + '%');
		}
		else {
			stickyHeader.removeClass('active');
		}
	}

}

function sliderCounter() {
	if ($('.js-slider-counter').length) {
		$('.js-slider-counter').each(function () {
			var slider = $(this),
				allSlidesLength = slider.find('.js-slider-item').length;

			slider.find('.js-all-slides').text(allSlidesLength);

			$('.js-slider-item', slider).each(function () {
				var sliderItem = $(this),
					currentSlideIndex = sliderItem.index() + 1;

				sliderItem.find('.js-current-slide').text(currentSlideIndex);

			});
		});
	}
}

function galleryGrid() {
	if ($('.js-gallery-images').length) {
		$('.js-gallery-images').each(function () {
			$(this).masonry({
				itemSelector: '.js-gallery-images-item'
			});
		});
	}
}

function closePopups() {
	$('body').removeClass('menu-opened popup-opened');
	$('.popup.active').removeClass('active');
}

function scrollToElement(element) {

	var height = 0;

	if ($('.js-sticky-header').length && $(window).innerWidth() > 768) {
		height = parseInt($('.js-sticky-header').height());
	}
	$('html, body').animate(
		{
			scrollTop: element.offset().top - height
		},
		500
	);
}
"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

function ResponsiveNavigation(nav) {
  var _this = this;

  this.nav = nav;
  this.navHolder = this.nav.querySelector('.js-resp-nav__holder');
  this.navList = this.nav.querySelector('.js-resp-nav__list');
  this.navItems = _toConsumableArray(this.nav.querySelectorAll('.js-resp-nav__item'));
  this.dropdown = null;
  this.dropdownButton = null;
  this.dropdownButtonText = this.nav.dataset.buttonText || null;
  this.breakpoint = Number(this.nav.dataset.mobileNavWidth) || 0;
  this.navListWidth = 0;
  this.navHolderWidth = 0;
  this.sum = 0;
  this.init();
  window.addEventListener('resize', function () {
    _this.init();
  });
}

ResponsiveNavigation.prototype.init = function () {
  this.addInitClass();
  this.resetElements();
  this.getWidths();
  this.compareWidths();
};

ResponsiveNavigation.prototype.addInitClass = function () {
  if (!this.navList.classList.contains('is-initialized')) {
    this.navList.classList.add('is-initialized');
  }
};

ResponsiveNavigation.prototype.removeInitClass = function () {
  if (this.navList.classList.contains('is-initialized')) {
    this.navList.classList.remove('is-initialized');
  }
};

ResponsiveNavigation.prototype.resetElements = function () {
  this.navItems.forEach(function (element, index) {
    element.classList.remove('is-hidden');
  });
};

ResponsiveNavigation.prototype.getWidths = function () {
  this.navHolderWidth = this.navHolder.clientWidth;
  this.navListWidth = this.navList.scrollWidth;
};

ResponsiveNavigation.prototype.compareWidths = function () {
  this.removeElements();
  this.getWidths();

  if (this.navListWidth > this.navHolderWidth) {
    this.createElements();
    this.checkSum();
  } else {
    this.hideDropdown();
  }
};

ResponsiveNavigation.prototype.createElements = function () {
  this.createDropdownButton();
  this.createDropdown();
};

ResponsiveNavigation.prototype.createDropdownButton = function () {
  var _this2 = this;

  if (!this.nav.querySelector('.js-resp-nav__button')) {
    this.dropdownButton = document.createElement('button');
    this.dropdownButton.setAttribute('type', 'button');
    this.dropdownButton.classList.add('resp-nav__button');
    this.dropdownButton.classList.add('js-resp-nav__button');

    if (this.dropdownButtonText) {
      this.dropdownButton.innerHTML = this.dropdownButtonText;
    }

    this.nav.appendChild(this.dropdownButton);
    this.dropdownButton.addEventListener('click', function () {
      _this2.toggleDropdown();
    });
  }
};

ResponsiveNavigation.prototype.createDropdown = function () {
  if (!this.nav.querySelector('.js-resp-nav__dropdown')) {
    this.dropdown = document.createElement('ul');
    this.dropdown.classList.add('resp-nav__dropdown');
    this.dropdown.classList.add('js-resp-nav__dropdown');
    this.nav.appendChild(this.dropdown);
  }
};

ResponsiveNavigation.prototype.createDropdownItem = function (href, text, activeClass, title) {
  var dropdownItem = document.createElement('li');
  dropdownItem.classList.add('resp-nav__dropdown-item');
  var dropdownLink = document.createElement('a');
  dropdownLink.classList.add('resp-nav__dropdown-link');
  dropdownLink.setAttribute('href', href);
  dropdownLink.innerHTML = text;

  if (activeClass) {
    dropdownLink.classList.add(activeClass);
  }

  if (title) {
    dropdownLink.setAttribute('title', title);
  }

  dropdownItem.appendChild(dropdownLink);
  console.log(this);
  this.dropdown.appendChild(dropdownItem);
};

ResponsiveNavigation.prototype.removeElements = function () {
  this.removeDropdownButton();
  this.removeDropdown();
};

ResponsiveNavigation.prototype.removeDropdown = function () {
  if (this.dropdown) {
    var parent = this.dropdown.parentNode;
    var index = Array.prototype.indexOf.call(parent.children, this.dropdown); //Get index of element that has to be removed

    parent.removeChild(parent.children[index]);
    this.dropdown = null;
  }
};

ResponsiveNavigation.prototype.removeDropdownButton = function () {
  if (this.dropdownButton) {
    var parent = this.dropdownButton.parentNode;
    var index = Array.prototype.indexOf.call(parent.children, this.dropdownButton); //Get index of element that has to be removed

    parent.removeChild(parent.children[index]);
    this.dropdownButton = null;
  }
};

ResponsiveNavigation.prototype.hideDropdown = function () {
  if (this.nav.classList.contains('is-open')) {
    this.nav.classList.remove('is-open');
  }
};

ResponsiveNavigation.prototype.showDropdown = function () {
  if (!this.nav.classList.contains('is-open')) {
    this.nav.classList.add('is-open');
  }
};

ResponsiveNavigation.prototype.toggleDropdown = function () {
  this.nav.classList.toggle('is-open');
};

ResponsiveNavigation.prototype.checkSum = function () {
  this.resetElements();
  this.getWidths();
  this.calcSum();
};

ResponsiveNavigation.prototype.calcSum = function () {
  var _this3 = this;

  this.sum = 0;
  this.navItems.forEach(function (element, index) {
    var itemWidth = element.scrollWidth;
    _this3.sum += itemWidth;

    if (window.innerWidth > _this3.breakpoint) {
      if (_this3.sum > _this3.navHolderWidth) {
        _this3.moveItemToDropdown(element);
      }
    } else {
      _this3.moveAllToDropdown();
    }
  });
};

ResponsiveNavigation.prototype.moveItemToDropdown = function (element) {
  element.classList.add('is-hidden');
  console.log(element);
  var hiddenItemHref = element.querySelector('.js-resp-nav__link').getAttribute('href');
  var hiddenItemTitle = element.querySelector('.js-resp-nav__link').getAttribute('title') || null;
  var hiddenItemText = element.querySelector('.js-resp-nav__link').textContent;
  var activeClass = null;

  if (element.querySelector('.js-resp-nav__link').classList.contains('is-active')) {
    activeClass = 'is-active';
  }

  this.createDropdownItem(hiddenItemHref, hiddenItemText, activeClass, hiddenItemTitle);
};

ResponsiveNavigation.prototype.moveAllToDropdown = function () {
  var _this4 = this;

  this.removeDropdown();
  this.createDropdown();
  this.resetElements();
  this.navItems.forEach(function (element, index) {
    _this4.moveItemToDropdown(element);
  });
};

ResponsiveNavigation.prototype.destroy = function () {
  this.removeInitClass();
  this.resetElements();
  this.removeElements();
};
// function ResponsiveNavigation(nav) {


// 	this.nav = nav;



// 	this.navHolder = this.nav.querySelector('.js-resp-nav__holder');

// 	this.navList = this.nav.querySelector('.js-resp-nav__list');

// 	this.navItems = [...this.nav.querySelectorAll('.js-resp-nav__item')];



// 	this.dropdown = null;

// 	this.dropdownButton = null;

// 	this.dropdownButtonText = this.nav.dataset.buttonText || null;



// 	this.breakpoint = Number(this.nav.dataset.mobileNavWidth) || 0;



// 	this.navListWidth = 0;

// 	this.navHolderWidth = 0;

// 	this.sum = 0;



// 	this.init();



// 	window.addEventListener('resize', () => {

// 		this.init();

// 	});

// }


// ResponsiveNavigation.prototype.init = function () {

// 	this.addInitClass();

// 	this.resetElements();

// 	this.getWidths();

// 	this.compareWidths();

// }



// ResponsiveNavigation.prototype.addInitClass = function () {

// 	if (!this.navList.classList.contains('is-initialized')) {

// 		this.navList.classList.add('is-initialized');

// 	}

// }



// ResponsiveNavigation.prototype.removeInitClass = function () {

// 	if (this.navList.classList.contains('is-initialized')) {

// 		this.navList.classList.remove('is-initialized');

// 	}

// }



// ResponsiveNavigation.prototype.resetElements = function () {

// 	this.navItems.forEach((element, index) => {

// 		element.classList.remove('is-hidden');

// 	});

// }



// ResponsiveNavigation.prototype.getWidths = function () {

// 	this.navHolderWidth = this.navHolder.clientWidth;

// 	this.navListWidth = this.navList.scrollWidth;

// }



// ResponsiveNavigation.prototype.compareWidths = function () {

// 	this.removeElements();

// 	this.getWidths();

// 	if (this.navListWidth > this.navHolderWidth) {

// 		this.createElements();

// 		this.checkSum();

// 	}

// 	else {

// 		this.hideDropdown();

// 	}

// }



// ResponsiveNavigation.prototype.createElements = function () {

// 	this.createDropdownButton();

// 	this.createDropdown();

// }



// ResponsiveNavigation.prototype.createDropdownButton = function () {

// 	if (!this.nav.querySelector('.js-resp-nav__button')) {

// 		this.dropdownButton = document.createElement('button');

// 		this.dropdownButton.setAttribute('type', 'button');



// 		this.dropdownButton.classList.add('resp-nav__button');

// 		this.dropdownButton.classList.add('js-resp-nav__button');



// 		if (this.dropdownButtonText) {

// 			this.dropdownButton.innerHTML = this.dropdownButtonText;

// 		}



// 		this.nav.appendChild(this.dropdownButton)

// 		this.dropdownButton.addEventListener('click', () => {

// 			this.toggleDropdown();

// 		});

// 	}

// }



// ResponsiveNavigation.prototype.createDropdown = function () {

// 	if (!this.nav.querySelector('.js-resp-nav__dropdown')) {

// 		this.dropdown = document.createElement('ul');

// 		this.dropdown.classList.add('resp-nav__dropdown');

// 		this.dropdown.classList.add('js-resp-nav__dropdown');



// 		this.nav.appendChild(this.dropdown);

// 	}

// }



// ResponsiveNavigation.prototype.createDropdownItem = function (href, text, activeClass, title) {

// 	const dropdownItem = document.createElement('li');

// 	dropdownItem.classList.add('resp-nav__dropdown-item');



// 	const dropdownLink = document.createElement('a');

// 	dropdownLink.classList.add('resp-nav__dropdown-link');

// 	dropdownLink.setAttribute('href', href);

// 	dropdownLink.innerHTML = text;

// 	if (activeClass) {

// 		dropdownLink.classList.add(activeClass);

// 	}

// 	if (title) {

// 		dropdownLink.setAttribute('title', title);

// 	}



// 	dropdownItem.appendChild(dropdownLink);
// 	console.log(this);

// 	this.dropdown.appendChild(dropdownItem);

// }



// ResponsiveNavigation.prototype.removeElements = function () {

// 	this.removeDropdownButton();

// 	this.removeDropdown();

// }



// ResponsiveNavigation.prototype.removeDropdown = function () {

// 	if (this.dropdown) {

// 		const parent = this.dropdown.parentNode;

// 		const index = Array.prototype.indexOf.call(parent.children, this.dropdown); //Get index of element that has to be removed

// 		parent.removeChild(parent.children[index]);

// 		this.dropdown = null;

// 	}

// }



// ResponsiveNavigation.prototype.removeDropdownButton = function () {

// 	if (this.dropdownButton) {

// 		const parent = this.dropdownButton.parentNode;

// 		const index = Array.prototype.indexOf.call(parent.children, this.dropdownButton); //Get index of element that has to be removed

// 		parent.removeChild(parent.children[index]);

// 		this.dropdownButton = null;

// 	}

// }



// ResponsiveNavigation.prototype.hideDropdown = function () {

// 	if (this.nav.classList.contains('is-open')) {

// 		this.nav.classList.remove('is-open');

// 	}

// }



// ResponsiveNavigation.prototype.showDropdown = function () {

// 	if (!this.nav.classList.contains('is-open')) {

// 		this.nav.classList.add('is-open');

// 	}

// }



// ResponsiveNavigation.prototype.toggleDropdown = function () {

// 	this.nav.classList.toggle('is-open');

// }



// ResponsiveNavigation.prototype.checkSum = function () {

// 	this.resetElements();

// 	this.getWidths();

// 	this.calcSum();

// }



// ResponsiveNavigation.prototype.calcSum = function () {

// 	this.sum = 0;

// 	this.navItems.forEach((element, index) => {

// 		const itemWidth = element.scrollWidth;

// 		this.sum += itemWidth;



// 		if (window.innerWidth > this.breakpoint) {

// 			if (this.sum > this.navHolderWidth) {

// 				this.moveItemToDropdown(element);

// 			}

// 		}

// 		else {

// 			this.moveAllToDropdown();

// 		}

// 	});

// }



// ResponsiveNavigation.prototype.moveItemToDropdown = function (element) {

// 	element.classList.add('is-hidden');

// 	console.log(element);


// 	var hiddenItemHref = element.querySelector('.js-resp-nav__link').getAttribute('href');

// 	var hiddenItemTitle = element.querySelector('.js-resp-nav__link').getAttribute('title') || null;

// 	var hiddenItemText = element.querySelector('.js-resp-nav__link').textContent;



// 	var activeClass = null;

// 	if (element.querySelector('.js-resp-nav__link').classList.contains('is-active')) {

// 		activeClass = 'is-active';

// 	}



// 	this.createDropdownItem(hiddenItemHref, hiddenItemText, activeClass, hiddenItemTitle);

// }



// ResponsiveNavigation.prototype.moveAllToDropdown = function () {

// 	this.removeDropdown();

// 	this.createDropdown();

// 	this.resetElements();



// 	this.navItems.forEach((element, index) => {

// 		this.moveItemToDropdown(element);

// 	});

// }



// ResponsiveNavigation.prototype.destroy = function () {

// 	this.removeInitClass();

// 	this.resetElements();

// 	this.removeElements();

// }
